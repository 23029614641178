import React, { useEffect } from "react";
import Head from "next/head";
import { Provider } from "react-redux";
import { useRouter } from "next/router";
import mixpanel from "mixpanel-browser";
import { useMutation } from "@apollo/client";
import "react-toastify/dist/ReactToastify.css";
import "../lt-dashboard/assets/css/global.css";
import { ToastContainer } from "react-toastify";
import "../lt-dashboard/assets/css/Calendar.css";
import "../lt-dashboard/assets/css/DatePicker.css";
import { ErrorBoundary } from "react-error-boundary";
import { withApollo } from "../lt-dashboard/lib/withApollo";
import { PersistGate } from "redux-persist/integration/react";
import Layout1 from "../lt-dashboard/components/Layouts/Layout1";
import Layout2 from "../lt-dashboard/components/Layouts/Layout2";
import Layout3 from "../lt-dashboard/components/Layouts/Layout3";
import Layout4 from "../lt-dashboard/components/Layouts/Layout4";
import Layout404 from "../lt-dashboard/components/Layouts/Layout404";
import store, { persistor } from "../lt-dashboard/Redux/Reducers/store";
import { ERROR_MESSAGE_TO_SLACK } from "../lt-dashboard/pages/api/mutations";

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY);
const layouts = {
  L1: Layout1,
  L2: Layout2,
  L3: Layout3,
  L4: Layout4,
  L404: Layout404,
};

function ErrorFallback({ error, resetErrorBoundary }) {
  console.log("error", error);

  return (
    <div className=" bg-landing min-h-screen flex-col flex justify-center items-center">
      <div className="flex items-center cursor-pointer">
        <img
          src="/Images/logo-circle.webp"
          width={"80px"}
          height={"80px"}
          className="rounded-full"
        />
        <img
          src="/Images/wordmark-luckytruck.webp"
          width={"300px"}
          height={"100px"}
          className="rounded-full"
        />
      </div>
      <p className=" font-hkMedium text-lg">Something went wrong</p>
      {process.env.NEXT_PUBLIC_NODE_ENV !== "production" ? (
        <pre>{error.message}</pre>
      ) : null}
      😞😞😞😞😞😞
    </div>
  );
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [errorMessageToSlack] = useMutation(ERROR_MESSAGE_TO_SLACK);
  const Layout = layouts[Component.layout] || ((children) => <>{children}</>);

  useEffect(() => {
    // Normal user Dashboard flow
    if (Component.layout === "L1") {
      const mainToken = localStorage.getItem("mainToken");
      const token = localStorage.getItem("token");
      let withOutDot = localStorage.getItem("withOutDot");
      if (!mainToken && !withOutDot && !token) {
        router.push(process.env.NEXT_PUBLIC_USER_SIGNUP);
      }
    }

    // Login signups page
    if (Component.layout === "L2") {
      const mainToken = localStorage.getItem("mainToken");
      const LTtoken = localStorage.getItem("LTtoken");
      const DispatcherToken = localStorage.getItem("DispatcherToken");
      if (mainToken) {
        router.replace(process.env.NEXT_PUBLIC_USER_DASHBOARD);
      } else if (LTtoken) {
        const identifyOldUserSession = localStorage.getItem("LTuserData");

        if (identifyOldUserSession) {
          localStorage.clear();
          router.replace(process.env.NEXT_PUBLIC_LT_TEAM_LOGIN);
        } else {
          router.replace(process.env.NEXT_PUBLIC_LT_HOME);
        }
      } else if (DispatcherToken) {
        router.replace(process.env.NEXT_PUBLIC_DISPATCHER_HOME);
      }
    }

    // LT broker dashboard
    if (Component.layout === "L3") {
      const mainToken = localStorage.getItem("mainToken");
      const LTtoken = localStorage.getItem("LTtoken");

      if (mainToken && LTtoken) {
        router.replace(process.env.NEXT_PUBLIC_USER_DASHBOARD);
        return;
      } else if (!LTtoken) {
        router.replace(process.env.NEXT_PUBLIC_LT_TEAM_LOGIN);
      }
    }
  }, [router.pathname]);

  const onError = async (error) => {
    if (process.env.NEXT_PUBLIC_NODE_ENV === "production") {
      const mainToken = localStorage.getItem("mainToken");

      try {
        await errorMessageToSlack({
          variables: {
            normalUser: mainToken ? true : false,
            message: `${error} Error Occurred at path:${router.pathname}`,
            ltUserName: "",
          },
        });
      } catch (error) {
        console.log("Catch Error", error);
      }
    } else {
      console.log("Catch Error", error);
    }
  };

  return (
    <Provider store={store}>
      <Head>
        <title>
          {process.env.NEXT_PUBLIC_THEME === "LT"
            ? "Trucking Insurance Provider | LuckyTruck"
            : "Trucking Insurance Provider | Flexport"}
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>

        <script
          defer
          src="https://cdn.jsdelivr.net/npm/pdfjs-dist@2.13.216/build/pdf.min.js"
        />
        {/* <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "LuckyTruck",
            "url": "https://www.luckytruck.com/",
            "logo": "https://www.luckytruck.com/Images/wordmark-luckytruck.webp",
            "sameAs": [
              "https://www.facebook.com/LuckyTruckPlatform",
              "https://www.luckytruck.com/Images/linkedin.png",
              "https://www.luckytruck.com/"
            ]
          }
        `}</script> */}

        {/* pwa meta tags */}
        <meta name="application-name" content="LuckyTruck" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="LuckyTruck" />
        <meta
          name="description"
          content="LuckyTruck | We're here to help! Buy commercial trucking insurance and manage it through one seamless platform. Get started now"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-TileColor" content="#2B5797" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#5468FF" />

        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/Images/logo152x152.png"
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/Images/logo180x180.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="167x167"
          href="/Images/logo167x167.png"
        />

        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/Images/logo32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/Images/logo16x16.png"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="icon" href="/favicon.ico" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="LuckyTruck" />
        <meta
          property="og:description"
          content="LuckyTruck | We're here to help! Buy commercial trucking insurance and manage it through one seamless platform. Get started now"
        />
        <meta property="og:site_name" content="LuckyTruck" />
        <meta
          property="og:url"
          content={process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL}
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL}/apple-touch-icon.png`}
        />
        {/* pwa meta tags end */}
      </Head>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
          <Layout>
            <ToastContainer
              autoClose={5000}
              bodyClassName={"font-hkBold text-inputColor"}
            />

            <Component {...pageProps} />
          </Layout>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
}

export default withApollo()(MyApp);
